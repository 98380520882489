<template>
  <sdPageHeader :title="title"> </sdPageHeader>
  <Main>
    <HorizontalFormStyleWrap>
      <sdCards headless>
        <a-form
          layout="horizontal"
          :model="formState"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          labelAlign="left"
          :rules="rules"
          @finish="handleSubmit"
        >
          <a-form-item name="kpi_korporat_id" label="Indikator Kinerja">
            <a-select
              placeholder="Pilih Indikator Kinerja"
              v-model:value="formState.kpi_korporat_id"
              show-search
              :filter-option="filterSelect"
              @change="changeKpiKorporat"
              :disabled="mode == 'Ubah' ? true : false"
              v-if="mode == 'Ubah' || mode == 'Tambah'"
            >
              <a-select-option v-for="option in options.kpiKorporat" :key="option.value">
                {{ option.text }}
              </a-select-option>
            </a-select>
            <span v-if="mode == 'Detail'">{{ formState.name }}</span>
          </a-form-item>
          <a-form-item name="month" label="Bulan">
            <a-select
              placeholder="Pilih Bulan"
              v-model:value="formState.month"
              show-search
              :filter-option="filterSelect"
              :disabled="mode == 'Ubah' ? true : false"
              v-if="mode == 'Ubah' || mode == 'Tambah'"
            >
              <template v-if="!variableKpiKorporats.is_akumulasi">
                <a-select-option v-for="option in options.month" :key="option.value">
                  {{ option.text }}
                </a-select-option>
              </template>

              <template v-else>
                <a-select-option v-for="option in options.month2" :key="option.value">
                  {{ option.text }}
                </a-select-option>
              </template>
            </a-select>
            <span v-if="mode == 'Detail'">{{ formState.monthName }}</span>
          </a-form-item>
          <template v-for="variable in variableKpiKorporats.data" :key="variable.id">
            <a-form-item
              :name="'realisasis[' + variable.id + ']'"
              :label="'Realisasi ' + variable.name"
              v-if="variable.kpi_korporat.jenis_variable_kpi_id != 2"
            >
              <a-input-number
                v-model:value="formState.realisasis[variable.id]"
                :placeholder="'Masukkan Realisasi ' + variable.name"
                v-if="mode == 'Ubah' || mode == 'Tambah'"
              />
              <span v-if="mode == 'Detail'">{{ formState.realisasis[variable.id] }}</span>
            </a-form-item>

            <a-form-item :name="'realisasis[' + variable.id + ']'" :label="'Realisasi ' + variable.name" v-else>
              <a-month-picker
                v-model:value="formState.realisasis[variable.id]"
                :placeholder="'Pilih ' + variable.name"
                format="MM-YYYY"
                valueFormat="YYYY-MM"
                v-if="mode == 'Ubah' || mode == 'Tambah'"
              />
              <span v-if="mode == 'Detail'">{{ formState.realisasis[variable.id] }}</span>
            </a-form-item>
          </template>
          <template v-if="mode == 'Ubah' || mode == 'Detail'">
            <a-form-item name="status" label="Status">
              <a-tag :color="formState.dataColor">{{ formState.statusKpi }}</a-tag>
            </a-form-item>
          </template>
          <template v-if="(mode == 'Ubah' || mode == 'Detail') && formState.statusKpi == 'UNREL'">
            <a-form-item name="alasan" label="Alasan">
              <span>{{ formState.alasan }}</span>
            </a-form-item>
          </template>
          <a-row>
            <a-col :lg="{ span: 18, offset: 6 }" :md="{ span: 15, offset: 9 }" :xs="{ span: 24, offset: 0 }">
              <div class="sDash_form-action" v-if="mode == 'Ubah' || mode == 'Tambah'">
                <sdButton class="sDash_form-action__btn" type="primary" size="large" htmlType="submit">
                  {{ isLoading ? 'Harap Menunggu...' : mode }}
                </sdButton>

                <router-link
                  v-if="mode == 'Tambah'"
                  :to="'/kpi/kpi-korporat/' + dateState.month + '/' + dateState.year"
                >
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>

                <router-link v-else :to="'/kpi/kpi-korporat/' + formState.month + '/' + formState.year">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Batal
                  </sdButton>
                </router-link>
              </div>
              <div class="sDash_form-action" v-else>
                <sdButton
                  class="sDash_form-action__btn"
                  type="primary"
                  size="large"
                  htmlType="button"
                  v-if="checkPermission('approve kpi korporat') && formState.statusKpi != 'REL'"
                  @click="handleApprove(formState.id, formState.month, formState.year, 'realisasi')"
                >
                  {{ isLoading ? 'Harap Menunggu...' : 'Approve' }}
                </sdButton>

                <sdButton
                  class="sDash_form-action__btn"
                  type="primary"
                  size="large"
                  htmlType="button"
                  v-if="checkPermission('approve kpi korporat') && formState.statusKpi == 'REL'"
                  @click="handleUnapprove(formState.id, formState.month, formState.year, 'realisasi')"
                >
                  {{ isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
                </sdButton>

                <router-link :to="'/kpi/kpi-korporat/' + $route.params.month + '/' + $route.params.year">
                  <sdButton class="sDash_form-action__btn" type="light" size="large" htmlType="button">
                    Kembali
                  </sdButton>
                </router-link>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </sdCards>
    </HorizontalFormStyleWrap>

    <a-modal v-model:visible="modalState.visible" title="Konfirmasi">
      <a-form
        layout="horizontal"
        :model="formModalState"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        labelAlign="left"
        :rules="modalRules"
        id="unapproveForm"
        @finish="doUnapprove"
      >
        Apakah Anda yakin ingin melakukan unapprove {{ modalState.type }} {{ modalState.kpi_korporat }}
        {{ modalState.month }} {{ modalState.year }}?
        <a-form-item name="alasan" label="Alasan">
          <a-input v-model:value="formModalState.alasan" placeholder="Masukkan Alasan" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button key="back" @click="handleCancel">Batal</a-button>
        <a-button class="sDash_form-action__btn" type="primary" size="large" htmlType="submit" form="unapproveForm">
          {{ modalState.isLoading ? 'Harap Menunggu...' : 'Unapprove' }}
        </a-button>
      </template>
    </a-modal>
  </Main>
</template>

<script>
import { HorizontalFormStyleWrap } from '../../../components/crud/style';
import { Main } from '../../styled';
import { useStore } from 'vuex';
import { reactive, computed, onMounted, toRaw } from 'vue';
import { useRoute } from 'vue-router';
import { getItem } from '@/utility/localStorageControl';
import { Modal } from 'ant-design-vue';
import { DataService } from '@/config/dataService/dataService';
import { notification } from 'ant-design-vue';

const RealisasiKpiKorporatForm = {
  name: 'RealisasiKpiKorporatForm',
  components: { HorizontalFormStyleWrap, Main },
  data() {
    return {
      title: this.mode + ' ' + this.module,
    };
  },
  props: ['mode', 'module'],
  setup(props) {
    const { state, dispatch } = useStore();
    const { params } = useRoute();
    const isLoading = computed(() => state.crud.loading);
    const crud = computed(() => state.crud.data);
    const dateObj = new Date();
    const profile = getItem('profile_plnbb');
    const permissions = getItem('permissions');
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const formState = reactive({
      kpi_korporat_id: undefined,
      month: dateObj.getUTCMonth() + 1,
      realisasis: [],
      name: '',
      monthName: '',
      statusKpi: null,
      dataColor: '',
      alasan: '',
    });

    const modalState = reactive({
      isLoading: false,
      visible: false,
      kpi_korporat: '',
      month: '',
      year: null,
      type: '',
    });

    const formModalState = reactive({
      id: null,
      alasan: '',
    });

    const dateState = reactive({
      month: params.month,
      year: params.year,
    });

    const options = reactive({
      kpiKorporat: [],
      month: [
        { value: 1, text: 'Januari' },
        { value: 2, text: 's.d. Februari' },
        { value: 3, text: 's.d. Maret' },
        { value: 4, text: 's.d. April' },
        { value: 5, text: 's.d. Mei' },
        { value: 6, text: 's.d. Juni' },
        { value: 7, text: 's.d. Juli' },
        { value: 8, text: 's.d. Agustus' },
        { value: 9, text: 's.d. September' },
        { value: 10, text: 's.d. Oktober' },
        { value: 11, text: 's.d. November' },
        { value: 12, text: 's.d. Desember' },
      ],
      month2: [
        { value: 1, text: 'Januari' },
        { value: 2, text: 'Februari' },
        { value: 3, text: 'Maret' },
        { value: 4, text: 'April' },
        { value: 5, text: 'Mei' },
        { value: 6, text: 'Juni' },
        { value: 7, text: 'Juli' },
        { value: 8, text: 'Agustus' },
        { value: 9, text: 'September' },
        { value: 10, text: 'Oktober' },
        { value: 11, text: 'November' },
        { value: 12, text: 'Desember' },
      ],
    });

    const variableKpiKorporats = reactive({
      is_akumulasi: false,
      data: {},
    });

    const labelCol = {
      lg: 6,
      md: 9,
      xs: 24,
    };

    const wrapperCol = {
      lg: 18,
      md: 15,
      xs: 24,
    };

    const handleSubmit = () => {
      const postData = toRaw(formState);

      if (variableKpiKorporats.data[0].kpi_korporat.jenis_variable_kpi_id == 2) {
        postData.realisasis.forEach((realisasi, key) => {
          let my = realisasi.split('-');
          postData.realisasis[key] = my[0] + '-' + my[1] + '-' + '01';
        });
      }

      if (props.mode == 'Tambah') {
        dispatch('axiosCrudSubmitDataRedirect', {
          url: 'laporan-kpi-korporat',
          data: postData,
          redirect: '/kpi/kpi-korporat/' + params.month + '/' + params.year,
        });
      } else if (props.mode == 'Ubah') {
        dispatch('axiosDataUpdateRedirect', {
          id: params.id,
          url: 'laporan-kpi-korporat',
          data: postData,
          redirect: '/kpi/kpi-korporat/' + formState.month + '/' + formState.year,
        });
      }
    };

    const rules = {
      kpi_korporat_id: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
      month: [
        {
          required: true,
          message: 'Harap Dipilih',
          trigger: 'blur',
          type: 'number',
        },
      ],
    };

    const filterSelect = (input, option) => {
      return option.children[0].children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const changeKpiKorporat = value => {
      dispatch('axiosCrudGetData', 'variable-kpi-korporats-by-kpi-korporat?kpi_korporat_id=' + value).then(() => {
        variableKpiKorporats.data = crud.value.data;
        variableKpiKorporats.is_akumulasi = crud.value.data[0].kpi_korporat.is_akumulasi;
      });
    };

    onMounted(() => {
      getData();
    });

    const getData = () => {
      dispatch('axiosCrudGetData', 'options-kpi-korporat-with-formula').then(() => {
        options.kpiKorporat = crud.value;

        if (props.mode == 'Tambah') {
          formState.kpi_korporat_id = parseInt(params.id);
          formState.month = parseInt(params.month);

          changeKpiKorporat(parseInt(params.id));
        }
      });

      if (props.mode != 'Tambah') {
        const data = {
          url: 'laporan-kpi-korporat',
          id: params.id,
        };

        dispatch('axiosSingleDataGet', data).then(() => {
          Object.keys(crud.value).forEach(key => {
            formState[key] = crud.value[key];
          });

          formState.name = crud.value.kpi_korporat.name;
          formState.year = crud.value.kpi_korporat.year;
          formState.statusKpi = crud.value.status;
          formState.alasan = crud.value.last_alasan;

          if (!crud.value.kpi_korporat.is_akumulasi) {
            // formState.monthName = options.month[crud.value.month].text;
            formState.monthName = options.month.find(month => month.value === crud.value.month).text;
          } else {
            // formState.monthName = options.month2[crud.value.month].text;
            formState.monthName = options.month2.find(month => month.value === crud.value.month).text;
          }

          switch (crud.value.status) {
            case 'CRTD':
              formState.dataColor = 'blue';
              break;
            case 'UPDT':
              formState.dataColor = 'orange';
              break;
            case 'REL':
              formState.dataColor = 'green';
              break;
            case 'UNREL':
              formState.dataColor = 'volcano';
              break;
            case 'DEL':
              formState.dataColor = 'red';
              break;
          }

          let kpi_korporat_id = crud.value.kpi_korporat_id;
          let month = crud.value.month;
          let jenis_variable_kpi_id = crud.value.kpi_korporat.jenis_variable_kpi_id;

          dispatch(
            'axiosCrudGetData',
            'variable-kpi-korporats-by-kpi-korporat?kpi_korporat_id=' + crud.value.kpi_korporat_id,
          ).then(() => {
            variableKpiKorporats.data = crud.value.data;

            dispatch(
              'axiosCrudGetData',
              'realisasi-variables-by-kpi-korporat?kpi_korporat_id=' + kpi_korporat_id + '&month=' + month,
            ).then(() => {
              crud.value.data.forEach(data => {
                if (jenis_variable_kpi_id == 2) {
                  formState.realisasis[data.variable_kpi_korporat_id] = data.realisasi_waktu;
                } else {
                  formState.realisasis[data.variable_kpi_korporat_id] = data.realisasi_nominal;
                }
              });
            });
          });
        });
      }
    };

    const checkPermission = permission => {
      if (profile.roles[0].name == 'Super Admin') {
        return true;
      }

      if (Array.isArray(permission)) {
        return permission.some(p => permissions.includes(p.toLowerCase()));
      }

      return permissions.includes(permission.toLowerCase());
    };

    const handleApprove = (id, month, year, type) => {
      Modal.confirm({
        title: 'Konfirmasi',
        content: `Apakah Anda yakin ingin melakukan approve ${type} ${formState.name} untuk bulan ${months[month]} ${year}?`,
        okText: 'Approve',
        cancelText: 'Batal',
        onOk: () => {
          dispatch('axiosDataApprove', {
            id,
            url: 'approve-' + type + '-kpi-korporat',
            getData: () => {
              getData();
            },
          });
        },
      });
    };

    const handleUnapprove = (id, month, year, type) => {
      getData();
      formModalState.id = id;
      modalState.kpi_korporat = formState.name;
      modalState.month = months[month];
      modalState.year = year;
      modalState.visible = true;
      modalState.type = type;
    };

    const handleCancel = () => {
      getData();
      modalState.visible = false;
    };

    const doUnapprove = () => {
      modalState.isLoading = true;

      DataService.post('unapprove-' + modalState.type + '-kpi-korporat/' + formModalState.id, {
        alasan: formModalState.alasan,
      }).then(response => {
        modalState.visible = false;
        modalState.isLoading = false;
        formModalState.id = null;
        formModalState.alasan = '';

        notification.success({
          message: response.data.message,
        });

        getData();
      });
    };

    const modalRules = {
      alasan: [
        {
          required: true,
          message: 'Harap Diisi',
          trigger: 'blur',
        },
      ],
    };

    return {
      formState,
      labelCol,
      wrapperCol,
      handleSubmit,
      isLoading,
      rules,
      options,
      filterSelect,
      changeKpiKorporat,
      variableKpiKorporats,
      dateState,
      formModalState,
      modalState,
      modalRules,
      checkPermission,
      handleApprove,
      handleUnapprove,
      handleCancel,
      doUnapprove,
    };
  },
};

export default RealisasiKpiKorporatForm;
</script>
